import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import {
  addChartToFirebase,
  getChartFromFirebase,
  updateChartInFirebase,
} from "../firebase";
import { render } from "react-dom";
import { Link, useParams } from "react-router-dom";
import DataEditor from "./DataEditor";
import ColorList from "./ColorList";
import BarChart from "./BarChart";
import ColorSelector from "./ColorSelector";

const Editor: React.FC = () => {
  const { vizuId } = useParams();

  const [chartId, setChartId] = useState<null | string>(null);

  const [pageWrapperWidth, setPageWrapperWidth] = useState(600);
  const [fixedChartWidth, setFixedChartWidth] = useState(600);
  const [fixedChartHeight, setFixedChartHeight] = useState(280);
  const [fixedMarginTop, setFixedMarginTop] = useState(20);
  const [fixedMarginBottom, setFixedMarginBottom] = useState(20);
  const [fixedMarginRight, setFixedMarginRight] = useState(20);

  const [showDataEditor, setShowDataEditor] = useState(false);
  const initialData = [["1"]];
  const [dataEditorData, setDataEditorData] = useState(initialData);

  const [fixedMarginLeft, setFixedMarginLeft] = useState(40);
  const [yAxisFontSize, setYAxisFontSize] = useState(12);
  const [xAxisFontSize, setXAxisFontSize] = useState(12);
  const [labelFontSize, setLabelFontSize] = useState(12);

  const [code, setCode] = useState("");

  const [data, setData] = useState<any[]>([
    { "2019": [{ Cats: 75 }, { Dogs: 54 }] },
    { "2020": [{ Cats: 72 }, { Dogs: 66 }] },
    { "2021": [{ Cats: 78 }, { Dogs: 65 }] },
    { "2022": [{ Cats: 75 }, { Dogs: 68 }] },
    { "2023": [{ Cats: 73 }, { Dogs: 71 }] },
    { "2024": [{ Cats: 79 }, { Dogs: 64 }] },
  ]);

  const [colors, setColors] = useState<string[]>([
    "#446699",
    "#FF9B25",
    "#dd5533",
  ]);
  const [lineThickness, setLineThickness] = useState(3.5);
  const [backgroundLinesType, setBackgroundLinesType] = useState("solid");
  const [chartType, setChartType] = useState("line");
  const [chartLoaded, setChartLoaded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#eeeeee");
  const [backgroundLineColor, setBackgroundLineColor] = useState("#ddd");

  useEffect(() => {
    if (vizuId !== undefined && vizuId !== "new") {
      /*
      setChartId(vizuId);
      getChartFromFirebase(vizuId).then((chartData) => {
        console.log("chartData " + chartData);
        setData(chartData!.data);
        setColors(chartData!.colors);
        setNewColor(colors[0]);
        setLineThickness(chartData!.lineThickness);
        setBackgroundLinesType(chartData!.backgroundLinesType);
        setChartLoaded(true);
        convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(
          chartData!.data
        );
      });
      */
    } else {
      setChartLoaded(true);
      // Set data in data editor to be equal to initial data
      convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(data);
    }
  }, [vizuId]);

  const updateDataFromEditorCallback = (newData: any[]) => {
    setData(newData);
    convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(newData);
  };

  const convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor = (
    newData: any
  ) => {
    const dataEditorArray: string[][] = [[]];

    // Push first non existing upper left
    dataEditorArray[0].push("");

    for (let i = 0; i < newData.length; i++) {
      const dataElement = newData[i] as any;
      const dataKey = Object.keys(dataElement)[0];

      dataEditorArray.push([dataKey]);

      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, index: number) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key, indexSubKeys) => {
          const value = _element[_key];
          dataEditorArray[i + 1].push(value);
          if (!dataEditorArray[0].includes(_key)) dataEditorArray[0].push(_key);
        });
      });
    }
    setDataEditorData(dataEditorArray);
  };

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div
        className="editorLeftSidebar"
        style={{
          backgroundColor: "#282c33",
          display: "flex",
          maxWidth: "4vw",
          minWidth: "80px",
          flexGrow: "0",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            alignItems: "stretch",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <img
              src="logo_white.svg"
              style={{
                height: "25px",
                paddingTop: "15px",
                paddingBottom: "10px",
                opacity: "1",
              }}
            />
          </div>

          <button
            className={showDataEditor ? "active" : "inactive"}
            onClick={() => setShowDataEditor(true)}
            style={{ borderTop: "1px solid #555" }}
          >
            Edit data
          </button>

          <button
            className={!showDataEditor ? "active" : "inactive"}
            style={{ borderBottom: "1px solid #555" }}
            onClick={() => setShowDataEditor(false)}
          >
            Edit chart
          </button>
        </div>
      </div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexGrow: "4",
          overflowX: "hidden",
          flexDirection: "column",
        }}
        id="outerWrapper"
      >
        {showDataEditor ? (
          <div
            style={{
              width: "100vw",
              padding: "10px",
              height: "100vh",
            }}
          >
            <DataEditor
              initialData={dataEditorData}
              onDataUpdate={updateDataFromEditorCallback}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "100vh",
              flexGrow: "4",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "100vh",
                flexGrow: "4",
                overflowX: "scroll",

                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flexGrow: "1",
                  minWidth: "0",
                  height: "100%",
                  alignItems: "center",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "1em" }}>
                  <input
                    type="range"
                    step="1"
                    min="1"
                    max="2000"
                    value={pageWrapperWidth}
                    onChange={(event) =>
                      setPageWrapperWidth(event.target.valueAsNumber)
                    }
                  ></input>
                </div>
                <div>
                  Chart area wrapper: <b>{pageWrapperWidth} px</b> (for
                  illustration purpose)
                </div>
                <div
                  style={{
                    width: pageWrapperWidth + "px",
                    height: "0.2em",
                    paddingLeft: "0em",
                    paddingRight: "0em",

                    margin: "1em auto",

                    marginBottom: "1em",
                    marginTop: "0.5em",
                    borderTop: "2px solid #888",
                    borderRight: "2px solid #888",
                    borderLeft: "2px solid #888",
                  }}
                ></div>
                {chartLoaded && (
                  <div
                    style={{
                      width: pageWrapperWidth + "px",
                      border: "2px dashed #666",
                      paddingTop: "2em",
                      paddingBottom: "2em",
                      backgroundColor: "#fff",
                      margin: "0 auto",
                    }}
                  >
                    <div style={{ height: "300px" }}>
                      {chartType === "line" && (
                        <LineChart
                          data={data}
                          colors={colors}
                          lineThickness={lineThickness}
                          backgroundLinesType={backgroundLinesType}
                          parentWidth={pageWrapperWidth}
                          fixedChartWidth={fixedChartWidth}
                          fixedChartHeight={fixedChartHeight}
                          fixedMarginLeft={fixedMarginLeft}
                          fixedMarginTop={fixedMarginTop}
                          fixedMarginBottom={fixedMarginBottom}
                          fixedMarginRight={fixedMarginRight}
                          backgroundColor={backgroundColor}
                          backgroundLineColor={backgroundLineColor}
                          yAxisFontSize={yAxisFontSize}
                          xAxisFontSize={xAxisFontSize}
                          labelFontSize={labelFontSize}
                          dataPointDots={false}
                        />
                      )}

                      {chartType === "bar" && (
                        <BarChart
                          data={data}
                          colors={colors}
                          lineThickness={lineThickness}
                          backgroundLinesType={backgroundLinesType}
                          parentWidth={pageWrapperWidth}
                          fixedChartWidth={fixedChartWidth}
                          fixedChartHeight={fixedChartHeight}
                          fixedMarginLeft={fixedMarginLeft}
                          fixedMarginTop={fixedMarginTop}
                          fixedMarginBottom={fixedMarginBottom}
                          fixedMarginRight={fixedMarginRight}
                          backgroundColor={backgroundColor}
                          backgroundLineColor={backgroundLineColor}
                          yAxisFontSize={yAxisFontSize}
                          xAxisFontSize={xAxisFontSize}
                          labelFontSize={labelFontSize}
                        />
                      )}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    marginTop: "30px",
                  }}
                >
                  {code}
                </div>
                <button
                  onClick={() => {
                    const element = document.getElementById("target");
                    if (element) setCode(element?.innerHTML);
                  }}
                >
                  Get code
                </button>
              </div>
              <div style={{ textAlign: "center", padding: "0.1em" }}>
                <Link to={"/privacy-policy"}>Privacy policy</Link>
              </div>
            </div>

            <div
              className="editorSidebar"
              style={{
                backgroundColor: "#fff",
                borderLeft: "1px solid #bbb",
                display: "flex",
                flexDirection: "column",

                padding: "5px",
                paddingLeft: "10px",
              }}
            >
              <h4>Category colors</h4>
              <ColorList
                initialData={colors}
                onDataUpdate={(newColors) => {
                  setColors(newColors);
                }}
              />
              <h4>Background color</h4>
              <ColorSelector
                initialData={backgroundColor}
                onDataUpdate={(newColor) => {
                  setBackgroundColor(newColor);
                }}
              ></ColorSelector>

              <h4>Chart width</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="2000"
                value={fixedChartWidth}
                onChange={(event) => {
                  setFixedChartWidth(event.target.valueAsNumber);
                }}
              />

              <h4>Chart height</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="2000"
                value={fixedChartHeight}
                onChange={(event) => {
                  setFixedChartHeight(event.target.valueAsNumber);
                }}
              />

              <h4>Margin left</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="150"
                value={fixedMarginLeft}
                onChange={(event) => {
                  setFixedMarginLeft(event.target.valueAsNumber);
                }}
              />

              <h4>Margin top</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="150"
                value={fixedMarginTop}
                onChange={(event) => {
                  setFixedMarginTop(event.target.valueAsNumber);
                }}
              />

              <h4>Margin bottom</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="150"
                value={fixedMarginBottom}
                onChange={(event) => {
                  setFixedMarginBottom(event.target.valueAsNumber);
                }}
              />

              <h4>Margin right</h4>
              <input
                type="range"
                step="1"
                min="1"
                max="150"
                value={fixedMarginRight}
                onChange={(event) => {
                  setFixedMarginRight(event.target.valueAsNumber);
                }}
              />

              <h4>Y-axis font size</h4>
              <input
                type="range"
                step="1"
                min="4"
                max="40"
                value={yAxisFontSize}
                onChange={(event) => {
                  setYAxisFontSize(event.target.valueAsNumber);
                }}
              />

              <h4>X-axis font size</h4>
              <input
                type="range"
                step="1"
                min="4"
                max="40"
                value={xAxisFontSize}
                onChange={(event) => {
                  setXAxisFontSize(event.target.valueAsNumber);
                }}
              />

              <h4>Label font size</h4>
              <input
                type="range"
                step="1"
                min="4"
                max="40"
                value={labelFontSize}
                onChange={(event) => {
                  setLabelFontSize(event.target.valueAsNumber);
                }}
              />

              <h4>Background Line type</h4>
              <select
                name="selectBackgroundLines"
                value={backgroundLinesType}
                onChange={(e) => setBackgroundLinesType(e.target.value)}
              >
                <option value="solid">Solid</option>
                <option value="dashed">Dashed</option>
              </select>

              <h4>Background line color</h4>
              <ColorSelector
                initialData={backgroundLineColor}
                onDataUpdate={(newColor) => {
                  setBackgroundLineColor(newColor);
                }}
              ></ColorSelector>

              <h4>Chart type</h4>
              <select
                name="selectChartType"
                value={chartType}
                onChange={(e) => setChartType(e.target.value)}
              >
                <option value="line">Line</option>
                <option value="bar">Bar</option>
              </select>

              {chartType === "line" && (
                <>
                  <h5>Line thickness</h5>
                  <input
                    type="range"
                    step="0.1"
                    min="0.1"
                    max="10"
                    value={lineThickness}
                    onChange={(event) => {
                      setLineThickness(event.target.valueAsNumber);
                    }}
                  />
                </>
              )}
              {false && (
                <button
                  onClick={async () => {
                    if (chartId !== null && chartId !== "new") {
                      updateChartInFirebase(
                        chartId,
                        data,
                        colors,
                        lineThickness,
                        backgroundLinesType
                      );
                    } else {
                      const newChartId = await addChartToFirebase(
                        data,
                        colors,
                        lineThickness,
                        backgroundLinesType
                      );
                      setChartId(newChartId);
                    }
                  }}
                >
                  Store chart
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editor;
